import { DateTime } from "luxon"

export function occurrences(string, subString, allowOverlapping = false) {
    string += ""
    subString += ""
    if (subString.length <= 0) return string.length + 1

    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length

    while (true) {
        pos = string.indexOf(subString, pos)
        if (pos >= 0) {
            ++n
            pos += step
        } else break
    }
    return n
}

export function removeA(arr) {
    var what,
        a = arguments,
        L = a.length,
        ax
    while (L > 1 && arr.length) {
        what = a[--L]
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1)
        }
    }
    return arr
}
DateTime.prototype.toISOTimeShort = function() {
    return this.toISOTime().substring(0, 5)
}

DateTime.prototype.toISOUTC = function() {
    return this.setZone("UTC").toISO()
}

DateTime.fromFields = function(date, time, zone) {
    return DateTime.fromObject({
        year: parseInt(date.substring(0, 4)),
        month: parseInt(date.substring(5, 7)),
        day: parseInt(date.substring(8, 10)),
        hour: parseInt(time.substring(0, 2)),
        minute: parseInt(time.substring(3, 5)),
        zone: zone
    })
}

Date.prototype.toLocalISODate = function(tz = "") {
    let dt = DateTime.fromJSDate(this)
    if (tz) {
        dt = dt.setZone(tz)
    }
    return dt.toISODate()
}

Date.prototype.toLocalISOTime = function(tz = "") {
    let dt = DateTime.fromJSDate(this)
    if (tz) {
        dt = dt.setZone(tz)
    }
    return dt.toISOTimeShort()
}

Date.prototype.toLocalDateTime = function(tz = "") {
    let dt = DateTime.fromJSDate(this)
    if (tz) {
        dt = dt.setZone(tz)
    }
    return dt
}

Date.prototype.toISOTime = function() {
    return this.toISOString().substring(11, 16)
}

Date.prototype.toISODate = function() {
    return this.toISOString().substring(0, 10)
}

Date.prototype.getWeek = function() {
    // Copy date so don't modify original
    let d = new Date(
        Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    )
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
    // Return array of year and week number
    return weekNo
}

export function sortMultiple(array, sortBy) {
    return array.sort((a, b) => {
        let i = 0,
            result = 0
        while (i < sortBy.length && result === 0) {
            result = sortBy[i](a, b)
            i++
        }
        return result
    })
}

export function pad(num, size) {
    var s = num + ""
    while (s.length < size) s = "0" + s
    return s
}

export function formatDuration(duration, opts) {
    if (!duration) return ""
    duration = duration.shiftTo("hours", "minutes")
    let prepend = ""
    if ((duration.minutes < 0 && duration.hours === 0) || duration.hours < 0) {
        prepend = "-"
    }
    if (opts && opts.supressHours && duration.hours === 0) {
        return `${prepend}${Math.ceil(Math.abs(duration.minutes))}min`
    }

    return `${prepend}${duration.hours}h ${Math.ceil(
        Math.abs(duration.minutes)
    )}min`
}

export function emailMatch(email) {
    return !!email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function bicMatch(bic) {
    return !!bic.match("^[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?$")
}

export function centsToMoney(cents) {
    cents = cents.toString()
    return (
        cents.substr(0, cents.length - 2) +
        "." +
        cents.substr(cents.length - 2, 2)
    )
}

export function moneyToCents(money) {
    return parseInt(money.replace(/\./, ""))
}

export function requiredRule(message) {
    return [v => !!v || message]
}

export function compareString(a, b) {
    return a < b ? -1 : a > b ? 1 : 0
}

export function download(name, link) {
    let linkEl = document.createElement("a")
    linkEl.download = name
    linkEl.href = link
    document.body.appendChild(linkEl)
    linkEl.click()
    document.body.removeChild(linkEl)
}

export function showFile(blob, type, filename) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob = new Blob([blob], { type })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    let link = document.createElement("a")
    link.href = data
    link.download = filename
    link.click()
    setTimeout(function() {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
    }, 100)
}
