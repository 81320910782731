<template lang="pug">
v-form(ref="form", v-on:submit.prevent="submit", :value="value", @input="input")
    slot(#default, name="default")
    v-row(no-gutters, :justify="centerSubmit ? 'center' : 'end'")
        slot(name="left-actions", :state="submitState")
        v-spacer(v-if="!centerSubmit")
        v-row(no-gutters, :justify="centerSubmit ? 'center' : 'end'")
            slot(name="actions", :state="submitState")
            slot(name="submit", :state="submitState", :on="submitOn")
</template>

<script>
export default {
    name: "FancyForm",
    props: {
        value: { default: false, type: Boolean },
        centerSubmit: { default: false, type: Boolean }
    },
    data() {
        return {
            state: "ready",
            valid: false
        }
    },
    computed: {
        submitOn() {
            return { submit: this.submit, "submit.invalid": this.submitInvalid }
        },
        submitState() {
            return this.valid ? null : "invalid"
        }
    },
    methods: {
        input(e) {
            this.valid = e
            this.$emit("input", e)
        },
        validate() {
            let res = this.$refs.form.validate()
            this.input(res)
            return res
        },
        resetValidation() {
            return this.$refs.form.resetValidation()
        },
        reset() {
            return this.$refs.form.reset()
        },
        submit() {},
        submitInvalid() {
            this.validate()
        }
    }
}
</script>
