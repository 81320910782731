import axios from "axios"
import Vuex from "vuex"
import auth from "./auth"
import permissions from "./permissions"
import timetable from "./timetable"
import settings from "./settings"
import myshifts from "./myshifts"
import filters from "./filters"
import hashbang from "./hashbang"
import navbar from "./navbar"
import { DateTime } from "luxon"
import VuexPersistence from "vuex-persist"

import Vue from "vue"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ["navbar", "filters", "permissions", "timetable"]
})

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    modules: {
        auth,
        permissions,
        settings,
        myshifts,
        filters,
        hashbang,
        navbar,
        timetable
    },
    state: {
        event: null,
        works: {},
        workId: null,
        events: [],
        crew: [],
        title: ""
    },
    getters: {
        work(state) {
            if (state.works) {
                return state.works[state.workId]
            }
            return null
        },
        works(state) {
            let result = {}
            for (let workId in state.works) {
                let work = state.works[workId]
                if (work.hasOwnProperty("id")) {
                    result[workId] = work
                }
            }
            return result
        },
        event(state) {
            if (!state.event) return state.event
            let copy = JSON.parse(JSON.stringify(state.event))

            copy.start = DateTime.fromISO(copy.start).setZone(
                state.settings.timezone
            )
            copy.end = DateTime.fromISO(copy.end).setZone(
                state.settings.timezone
            )
            return copy
        },
        events(state) {
            if (!state.events) return state.events.splice(0)
            let copy = JSON.parse(JSON.stringify(state.events))

            for (let event of copy) {
                event.start = DateTime.fromISO(event.start).setZone(
                    state.settings.timezone
                )
                event.end = DateTime.fromISO(event.end).setZone(
                    state.settings.timezone
                )
            }
            return copy
        },
        crewMap(state) {
            if (!state.crew) return {}
            return state.crew.reduce((map, obj) => {
                map[obj.id] = obj
                return map
            }, {})
        }
    },
    mutations: {
        title(state, title) {
            state.title = title
        },
        setEvents(state, events) {
            state.events = events
        },
        setCrew(state, crew) {
            state.crew = crew.map(x => {
                return { ...x, full_name: x.first_name + " " + x.last_name }
            })
        },
        setEvent(state, event) {
            event.roles = {}
            state.event = event
        },
        setEventPerms(state, perms) {
            state.event.perms = {
                showStats: perms.stats,
                showAddWork: perms.add_work,
                showEdit: perms.change_event,
                showCommittees: perms.change_event_committees,
                showCrew: perms.crew_perm,
                showBookings: perms.booking_perm,
            }
            for (let workId in perms.works) {
                let workPerms = perms.works[workId]

                let work = state.works[workId]
                if (!work) {
                    work = state.works[workId] = {}
                }

                work.perms = {
                    viewCrew: workPerms.view_work_crew,
                    changeWorkAssignedCrew: workPerms.change_work_assigned_crew,
                    changeCommittees: workPerms.change_work_committees,
                    changeWork: workPerms.change_work
                }
            }
            state.works = Object.assign({}, state.works)
        },
        setRoles(state, roles) {
            if (state.event && roles != null) {
                state.event.roles = roles.reduce((map, obj) => {
                    map[obj.id] = obj
                    return map
                }, {})
            }
        },
        setWorks(state, works) {
            if (!works) {
                state.works = works
                return
            }
            state.works = works
                .map(work => {
                    work.tasks = {}
                    let curr = state.works[work.id]
                    if (curr && curr.perms) {
                        work.perms = curr.perms
                    }
                    return work
                })
                .reduce((map, obj) => {
                    map[obj.id] = obj
                    return map
                }, {})
        },
        setTasks(state, tasks) {
            if (state.works && tasks != null) {
                for (let workId in state.works) {
                    state.works[workId].tasks = {}
                }
                for (let task of tasks) {
                    state.works[task.work].tasks[task.id] = task
                }
            }
        },
        setWork(state, id) {
            state.workId = id
        },
        setWorkPerms(state, obj) {
            let work = state.works[obj.workId]
            if (!work) {
                work = state.works[obj.workId] = {}
            }

            work.perms = {
                viewCrew: obj.perms.view_work_crew,
                changeWorkAssignedCrew: obj.perms.change_work_assigned_crew,
                changeCommittees: obj.perms.change_work_committees
            }

            Vue.set(state.works, obj.workId, Object.assign({}, work))
        }
    },
    actions: {
        useEventTimezone({ state }) {
            state.settings.timezone = state.event.timezone
        },
        fetchEvents({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/events")
                    .then(response => {
                        commit("setEvents", response.data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchCrew({ commit, state }, require = false) {
            return new Promise((resolve, reject) => {
                if (require && state.crew && state.crew.length > 0) {
                    resolve()
                } else {
                    axios
                        .get("/api/crew/list")
                        .then(response => {
                            commit("setCrew", response.data)
                            resolve()
                        })
                        .catch(() => {
                            reject()
                        })
                }
            })
        },
        requireCrew({ dispatch }) {
            return dispatch("fetchCrew", true)
        },
        fetchEvent({ state, commit }, id = null) {
            return new Promise((resolve, reject) => {
                if (id) {
                    if (state.event !== null && state.event.id === id) resolve()
                } else if (state.event) {
                    id = state.event.id
                    if (!id) reject()
                } else {
                    reject()
                }

                Promise.all([
                    axios.get("/api/event/" + id),
                    axios.get("/api/roles?event=" + id),
                    axios.get("/api/works?event=" + id),
                    axios.get("/api/tasks?event=" + id),
                    axios.get(`/api/event/${id}/perms?works=true`)
                ])
                    .then(responses => {
                        commit("timezone", responses[0].data.timezone)
                        commit("setEvent", responses[0].data)
                        commit("setRoles", responses[1].data)
                        commit("setWorks", responses[2].data)
                        commit("setTasks", responses[3].data)
                        commit("setEventPerms", responses[4].data)

                        resolve()
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        fetchWorkPerms({ state, commit }, id = null) {
            return
            if (id === null) {
                id = state.workId
            }
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/work/${id}/perms`)
                    .then(response => {
                        commit("setWorkPerms", {
                            workId: id,
                            perms: response.data
                        })

                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
})
