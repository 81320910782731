import axios from "axios"

export default {
    state: {
        iCalId: "",
        iCalLink: "",
        events: []
    },

    mutations: {
        MyShiftsiCalApiResponse(state, data) {
            state.iCalLink = data.feed_link
            state.iCalId = data.ical_id
        },
        MyShiftsEvents(state, val) {
            state.events = val
        }
    },
    actions: {
        fetchICalId({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (state.iCalId) {
                    resolve(state)
                }
                axios
                    .get("/api/crew/self/ical")
                    .then(response => {
                        commit("MyShiftsiCalApiResponse", response.data)
                        resolve(state)
                    })
                    .catch(error => {
                        console.log(error)
                        reject()
                    })
            })
        }
    }
}
