<template lang="pug">
v-dialog(:value="value", @input="onInput", :content-class="contentClass", :fullscreen="isFullscreen", :max-width="maxWidth")
    template(#activator="{ on }")
        slot(name="activator", :on="on")
    v-card-title.headline(v-if="title") {{title}}
    v-card-title.headline(v-else)
        slot(name="title")
    v-card-text
        v-container(fluid)
            fancy-form(ref="form", v-model="valid", v-if="renderContent")
                slot(#default, name="default")
                template(#left-actions)
                    slot(name="left-actions", :close="close")

                template(#actions)
                    v-btn(text, @click="abort") {{abortText}}
                template(#submit="{ state, on }")
                    fancy-button.ml-2(v-on="on", :state="state", :promise="promise", @success="onSuccess",  @error="onError", :disabled="disabled")  {{submitText}}

</template>

<script>
export default {
    name: "FancyDialog",
    components: {},
    props: {
        value: { type: Boolean },
        title: { type: String, default: "" },
        abortText: { type: String, default: "Abbrechen" },
        submitText: { type: String, default: "Speichern" },
        promise: { default: null, type: Function },
        contentClass: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        successInstantClose: { type: Boolean, default: false },
        fullscreen: { type: Boolean, default: false },
        fullscreenXs: { type: Boolean, default: true },
        maxWidth: { default: "1000px" }
    },
    data: function() {
        return {
            valid: false,
            renderContent: false
        }
    },
    computed: {
        isFullscreen() {
            return (
                this.fullscreen ||
                (this.fullscreenXs && this.$vuetify.breakpoint.xsOnly)
            )
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.renderContent = true
                if (this.isFullscreen) {
                    this.$nextTick().then(() => {
                        let el = document.querySelector(
                            ".v-dialog__content.v-dialog__content--active .v-dialog.v-dialog--active.v-dialog--fullscreen"
                        )
                        this.$vuetify.goTo(0, {
                            container: el,
                            duration: 0
                        })
                    })
                }
            } else {
                new Promise(resolve => setTimeout(resolve, 100)).then(() => {
                    this.renderContent = false
                })
            }
        }
    },
    methods: {
        onSuccess(response) {
            if (this.successInstantClose) {
                this.$emit("input", false)
                this.reset()
            } else {
                new Promise(resolve => setTimeout(resolve, 500)).then(() => {
                    this.$emit("input", false)
                    this.reset()
                })
            }

            this.$emit("success", response)
        },
        onError(error) {
            this.$emit("error", error)
        },
        onInput(open) {
            this.$emit("input", open)
            if (!open) {
                this.reset()
            }
        },
        abort() {
            this.$emit("input", false)
            this.$emit("abort")
            this.reset()
        },
        close() {
            this.$emit("input", false)
            this.reset()
        },
        reset() {
            new Promise(resolve => setTimeout(resolve, 100)).then(() => {
                this.$refs.form.reset()
                this.$emit("reset")
            })
        }
    }
}
</script>

<style></style>
