export default {
    namespaced: true,
    state: {
        zoom: 1.0
    },
    mutations: {
        zoom(state, zoom) {
            state.zoom =
                Math.round(100 * Math.min(Math.max(zoom, 0.5), 2.0)) / 100
        }
    },
    actions: {}
}
