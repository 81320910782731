<template lang="pug">
v-app(v-if="present", :style="appStyle", :class="appClass")
    global-events(@dragstart="closeDrawerOnMobile()")
    v-navigation-drawer(v-if="loggedIn && presentDrawer" v-show="!noDrawer", :value='actualDrawerOpen', :mini-variant="drawerMini", @input="drawerInput", app, clipped, fixed, disable-resize-watcher, disable-route-watcher, :mini-variant-width="72", :mobile-break-point="navDrawerMobileBreakpoint")
        router-view(name='nav')
        index-nav(v-show="showDefaultNavbar")
    v-app-bar(app, clipped-left, fixed)
        v-app-bar-nav-icon(v-show="!noDrawer && presentDrawer && loggedIn", @click.stop='userToggleDrawer')
        v-toolbar-title.headline.text-uppercase


            logo(v-if="$vuetify.breakpoint.lgAndUp || !routeTitle" @click='toIndex')
            v-row(no-gutters, v-else-if="$vuetify.breakpoint.smAndUp || !routeHasToolbar", align="center", justify="start")
                div.opaque.route-title(@click='toIndex', :class="{'route-title-max-width': routeHasToolbar}") {{routeTitle}}
        v-spacer

        div.toolbar-title-center(v-if="$vuetify.breakpoint.lgAndUp")
            div
                span.toolbar-title-center__title.headline.text-uppercase.route-title.route-title-max-width {{routeTitle}}

        v-row(no-gutters, justify="end", v-if="userAvatar !== ''", align="center")
            v-col(shrink)
                router-view(name='toolbar')
            div(style="width: 16px;")
            v-col(style="max-width: 48px;")
                v-row(no-gutters, justify="end")
                    user-avatar()
        v-btn(color='secondary', v-else-if="showLogin", @click="toLogin", key="2") Login
        v-btn(color='secondary', v-else-if="showRegister", router-link, to="/register", key="3") Registrieren

    v-content
        v-container(fluid, ref="scrollContainer")
            div
                router-view()

</template>

<script>
import GlobalEvents from "vue-global-events"
import Logo from "@/components/Logo.vue"
import Vue from "vue"

export default {
    name: "App",
    components: {
        GlobalEvents,
        UserAvatar: () =>
            import(
                /* webpackChunkName: "group-app" */ "@/components/UserAvatar.vue"
            ),
        IndexNav: () =>
            import(
                /* webpackChunkName: "group-app" */ "./views/Nav/IndexNav.vue"
            ),
        Logo
    },
    data() {
        return {
            present: false,
            presentDrawer: false,
            navDrawerMobileBreakpoint: 1264
        }
    },

    computed: {
        appStyle() {
            return { "background-color": "#fff" }
        },
        appClass() {
            let result = {}
            result["v-application--is-" + this.$vuetify.breakpoint.name] = true
            return result
        },
        loggedIn() {
            return this.$store.getters.loggedIn
        },
        userAvatar() {
            return this.$store.state.auth.user !== null
                ? this.$store.state.auth.user.first_name[0]
                : ""
        },
        userNameFull() {
            return this.$store.state.auth.user !== null
                ? this.$store.state.auth.user.first_name +
                      " " +
                      this.$store.state.auth.user.last_name
                : ""
        },
        showRegister() {
            return !this.loggedIn && this.$route.name != "register"
        },
        showLogin() {
            return !this.loggedIn && this.$route.name != "login"
        },
        routeTitle() {
            return this.$store.state.title || this.$route.meta.title || ""
        },
        showDefaultNavbar() {
            let matchedRoute = this.$route.matched[
                this.$route.matched.length - 1
            ]
            return matchedRoute ? !matchedRoute.components.nav : false
        },
        routeHasToolbar() {
            let matchedRoute = this.$route.matched[
                this.$route.matched.length - 1
            ]
            return matchedRoute ? !!matchedRoute.components.toolbar : false
        },
        noDrawer() {
            if (!this.$route) return true
            return this.$route.meta.noDrawer || false
        },
        drawerOpen() {
            return this.$store.state.navbar.drawerOpen
        },
        drawerOpenedByUser() {
            return this.$store.state.navbar.drawerOpenedByUser
        },
        drawerClosedByUser() {
            return this.$store.state.navbar.drawerClosedByUser
        },
        actualDrawerOpen() {
            if (this.$vuetify.breakpoint.lgAndUp) {
                return true
            }
            return this.drawerOpen
        },
        drawerMini() {
            if (this.$vuetify.breakpoint.lgAndUp) {
                return !this.drawerOpen
            }
            return false
        },
        screenWidth() {
            return this.$vuetify.breakpoint.width
        }
    },
    watch: {
        $route() {
            this.routeDrawer()
        },
        drawerOpen(newVal, oldVal) {
            if (typeof oldVal === "undefined") {
                this.routeDrawer()
            }
        },
        drawerMini(val) {
            this.$store.commit("drawerMini", val)
        },
        screenWidth(newVal, oldVal) {
            if (
                newVal < this.navDrawerMobileBreakpoint &&
                oldVal >= this.navDrawerMobileBreakpoint
            ) {
                this.closeDrawer()
                this.presentDrawer = false

                this.$nextTick().then(() => {
                    this.presentDrawer = true
                })
            }
        }
    },
    created() {
        Vue.prototype.$closeDrawer = this.closeDrawer
        Vue.prototype.$closeDrawerOnMobile = this.closeDrawerOnMobile
        Vue.prototype.$openDrawer = this.openDrawer

        this.$store
            .dispatch("refreshToken")
            .then(() => {})
            .catch(() => {})
    },
    mounted() {
        this.present = true
        this.$nextTick().then(() => {
            Vue.prototype.$scrollContainer = this.$refs.scrollContainer
            this.presentDrawer = true
        })
        if (!this.$vuetify.breakpoint.lgAndUp && this.drawerOpen) {
            this.$store.commit("drawerOpenUser", false)
        }
    },
    methods: {
        toIndex() {
            if (this.$store.getters.loggedIn) {
                this.$router.push("/")
            } else {
                this.$router.push({ name: "login" })
            }
        },
        closeDrawerOnMobile() {
            if (this.screenWidth < this.navDrawerMobileBreakpoint) {
                this.closeDrawer()
            }
        },
        openDrawer() {
            this.$store.commit("drawerOpen", true)
        },
        closeDrawer() {
            this.$store.commit("drawerOpen", false)
        },
        userToggleDrawer() {
            this.$store.commit("drawerOpenUser", !this.drawerOpen)
        },
        routeDrawer() {
            if (this.$vuetify.breakpoint.lgAndUp) {
                if (this.$route.meta.drawer === true) {
                    if (!this.drawerClosedByUser && !this.drawerOpenedByUser) {
                        this.openDrawer()
                    }
                } else if (this.$route.meta.drawer === false) {
                    if (!this.drawerOpenedByUser && this.drawerClosedByUser) {
                        this.closeDrawer()
                    }
                } else {
                    /* if (this.drawerClosedByUser) {
                        this.$store.commit("drawerOpen", false)
                    } else if (this.drawerOpenedByUser) {
                        this.$store.commit("drawerOpen", true)
                    } */
                }
            }
        },
        drawerInput(val) {
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.$store.commit("drawerOpenUser", val)
            }
        },
        toLogin() {
            let name = this.$route.name
            if (
                name === "register" ||
                name === "forgot-password" ||
                name === "login"
            ) {
                this.$router.push({ name: "login" })
            } else {
                let current = this.$route.fullPath
                this.$router.push({ name: "login", query: { next: current } })
            }
        },
        onScroll() {}
    }
}
</script>
<style lang="stylus">
main.v-content
    position fixed
    height 100vh
    max-height 100vh
    width 100vw
    max-width 100vw

    .container
        padding-top 16px
        padding-bottom 0px
        max-height 100%
        height 100%
        width 100%
        overflow-y auto
        overflow-x hidden

        > div
            position relative
            margin-top 4px
            max-height calc(100% - 4px)
            height 100%
            width 100%
</style>
<style scoped>
.route-title {
    word-wrap: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.route-title-max-width {
    max-width: 30vw;
}
.toolbar-title-center {
    position: absolute;
    width: 100%;
    text-align: center;
    pointer-events: none;
    user-select: none;
    left: 0;
}
.toolbar-title-center > div {
    text-align: center;
}
.toolbar-title-center__title {
    display: inline-block;
    padding: 10px;
}

.center-router-toolbar {
    width: 0;
    height: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 100;
    pointer-events: all;
}

.center-router-toolbar__content {
    display: inline-block;
    position: absolute;
}

.router-toolbar-mobile,
.router-toolbar-mobile * {
    opacity: 1;
}
</style>
