<template lang="pug">
div.logo(@click="onClick")
    span.acro Sil
    span pion 
    <br>
    span E
    span.acro vents
    <br>
    span.acro Kalender
</template>

<script>
export default {
    name: "Logo",
    methods: {
        onClick(e) {
            this.$emit("click", e)
        }
    }
}
</script>
<style scoped>
.logo {
    text-transform: uppercase;
    font-size: 17px;
    line-height: 15px;
    font-weight: 300 !important;
    color: rgba(0, 0, 0, 0.7);
}
.acro {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}
</style>
