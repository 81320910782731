import axios from "axios"
import router from "@/router"
import store from "@/store"

var goToLoginInterval = null
function goToLogin() {
    // handels redirecting to login, even when vue-router has not been instantiated/initialized yet

    // check if already tring to got to login || already at login
    if (goToLoginInterval || router.currentRoute.name === "login") return

    // counter to guard from endless loops
    var routeCheckCounter = 0

    // current window path without url prefix
    var routeNameRaw = window.location.pathname.substr(
        router.options.base ? router.options.base.length + 1 : 0
    )

    goToLoginInterval = setInterval(() => {
        // abort after 1000 tries
        routeCheckCounter += 1
        if (routeCheckCounter > 1000) {
            clearInterval(goToLoginInterval)
            goToLoginInterval = null
        }
        // if router path matches window path or router route as a name, we can be sure that router ist loaded
        if (
            router.currentRoute.fullPath === routeNameRaw ||
            router.currentRoute.name
        ) {
            // clear interval and got to login if required
            clearInterval(goToLoginInterval)
            goToLoginInterval = null
            if (!router.currentRoute.meta.noAuth) {
                router.push({ name: "login" })
            }
        }
    }, 33)
}

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 401) {
            store
                .dispatch("refreshToken")
                .then(() => {})
                .catch(() => {
                    goToLogin()
                })
        } else if (
            error.response.status === 400 &&
            error.request.responseURL.endsWith("/auth/token/refresh")
        ) {
            goToLogin()
        }
        return Promise.reject(error)
    }
)

export default {
    state: {
        refreshingToken: false,
        user: null,
        crewId: null,
        permissions: [],
        is_superuser: false,
        endpoints: {
            obtainJWT: "/auth/token/obtain",
            refreshJWT: "/auth/token/refresh",
            deleteJWT: "/auth/token/delete"
        }
    },
    getters: {
        loggedIn: state => {
            return state.crewId !== null
        }
    },
    mutations: {
        setRefreshingToken(state, val) {
            state.refreshToken = val
        },
        setDjangoUser(state, data) {
            if (!data) {
                state.crewId = null
                state.groups = []
                return
            }
            state.crewId = data["user"]
            state.permissions = data["permissions"]
            state.is_superuser = data["is_superuser"]
        },
        resetUser(state) {
            state.user = null
        },
        setUserData(state, data) {
            state.user = data
        },
        setGroups(state, data) {
            state.groups = data
        }
    },
    actions: {
        updateUser({ state, commit }) {
            if (state.crewId === 0) return
            axios
                .get("/api/crew/self")
                .then(response => {
                    commit("setUserData", response.data)
                })
                .catch(() => {})
        },
        obtainToken({ state, commit }, params) {
            const payload = {
                email: params.email,
                password: params.password
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(state.endpoints.obtainJWT, payload)
                    .then(response => {
                        if ("user" in response.data) {
                            commit("setDjangoUser", response.data)
                            this.dispatch("updateUser")
                        } else {
                            console.warn("no user provided")
                        }
                        resolve()
                    })
                    .catch(error => {
                        try {
                            reject(error.response["status"])
                        } catch {
                            reject(null)
                        }
                    })
            })
        },
        refreshToken({ state, commit }) {
            if (state.refreshToken) {
                return Promise.resolve()
            }
            commit("setRefreshingToken", true)
            return new Promise((resolve, reject) => {
                axios
                    .post(state.endpoints.refreshJWT)
                    .then(response => {
                        if ("user" in response.data) {
                            commit("setDjangoUser", response.data)
                            this.dispatch("updateUser")
                        } else {
                            console.warn("no user provided")
                        }
                        commit("setRefreshingToken", false)

                        resolve()
                    })
                    .catch(error => {
                        commit("setRefreshingToken", false)

                        try {
                            reject(error.response["status"])
                        } catch {
                            reject(null)
                        }
                    })
            })
        },
        deleteToken({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(state.endpoints.deleteJWT)
                    .then(() => {
                        this.commit("resetUser")
                        commit("setDjangoUser", null)
                        resolve()
                    })
                    .catch(error => {
                        try {
                            reject(error.response["status"])
                        } catch {
                            reject(null)
                        }
                    })
            })
        }
    }
}
