import router from "@/router"
import store from "@/store"

router.afterEach((to, from) => {
    let eventId = to.params.event
    if (eventId) {
        eventId = parseInt(eventId)
        if (store.state.event === null || store.state.event.id != eventId) {
            store.dispatch("fetchEvent", eventId)
        }
    }
})
