<template lang="pug">
v-btn.fancy(:color="currentColor", :disabled="isDisabled", @click="click", :loading="computedState === 'loading'", :class="classes", :style="style", :type="type", :text="flat", :block="block", ref="button") 
    v-icon(v-if="computedState === 'success'") done
    v-icon(v-else-if="computedState === 'error'") clear
    v-icon(v-else-if="computedState === 'warning'") warning
    slot(v-else-if="defaultState")
</template>

<script>
export default {
    name: "FancyButton",
    props: {
        persitentSuccess: { default: false, type: Boolean },
        noLoad: { default: false, type: Boolean },
        state: { default: null, type: String },
        disabled: { default: false, type: Boolean },
        submit: { default: false, type: Boolean },
        flat: { default: false, type: Boolean },
        block: { default: false, type: Boolean },
        label: { default: "Speichern", type: String },
        color: { default: "primary", type: String },
        icon: { default: "", type: String },
        showWarning: { default: false, type: null },

        promise: { default: null, type: Function }
    },
    data() {
        return {
            internalState: "ready",
            width: null
        }
    },
    computed: {
        computedState() {
            return this.state || this.internalState
        },
        type() {
            return this.submit ? "submit" : "button"
        },
        classes() {
            return {
                "error-shake": this.computedState === "error"
            }
        },
        style() {
            let result = {}
            if (!this.defaultState) {
                result["width"] = this.width
            }
            return result
        },
        currentColor() {
            switch (this.computedState) {
                case "ready":
                    return this.color
                case "loading":
                    return this.color
                case "success":
                    return "success"
                case "error":
                    return "error"
                case "warning":
                    return "warning"
                default:
                    return this.color
            }
        },
        isDisabled() {
            if (this.computedState === "loading") return true
            if (
                this.computedState === "success" ||
                this.computedState === "error"
            )
                return false

            return this.disabled
        },
        defaultState() {
            return (
                this.computedState === "ready" ||
                this.computedState === "invalid"
            )
        }
    },
    watch: {
        defaultState(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.lockWidth()
            }
        }
    },
    methods: {
        click(e, noRetry = false) {
            if (this.computedState === "ready") {
                if (!this.noLoad) {
                    this.internalState = "loading"
                }
                var self = this
                if (this.promise) {
                    this.promise()
                        .then(response => {
                            self.success(response)
                        })
                        .catch(error => {
                            if (self.showWarning === true) {
                                self.warning(error)
                            } else if (
                                Array.isArray(self.showWarning) &&
                                self.showWarning.includes(error.response.status)
                            ) {
                                self.warning(error)
                            } else {
                                self.error(error)
                            }
                        })
                }
                this.$emit("click", e)
                this.$emit("submit", e)
            } else if (this.computedState === "invalid") {
                this.$emit("submit.invalid", e)
                if (!noRetry) {
                    this.$nextTick().then(() => {
                        this.click(e, true)
                    })
                    return
                }
            }
        },
        lockWidth() {
            let current = this.$refs.button.$el.getBoundingClientRect().width
            this.width = current + "px"
        },
        success(data) {
            this.$emit("success", data)
            if (this.internalState !== "success") {
                this.internalState = "success"

                return new Promise(resolve => {
                    let wait = setTimeout(() => {
                        window.clearTimeout(wait)
                        this.internalState = "ready"
                        this.$emit("success:delay", data)

                        resolve()
                    }, 1000)
                })
            }
        },
        error(data) {
            if (this.internalState !== "error") {
                this.$emit("error", data)

                this.internalState = "error"
                setTimeout(() => {
                    this.internalState = "ready"
                    this.$emit("error:delay", data)
                }, 1000)
            }
        },
        warning(data) {
            this.$emit("warning", data)
            if (this.internalState !== "warning") {
                this.internalState = "warning"
                setTimeout(() => {
                    this.internalState = "ready"
                    this.$emit("warning:delay", data)
                }, 1000)
            }
        }
    }
}
</script>
<style>
.fancy {
    transition: 0.2s;
}
</style>
