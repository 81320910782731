export default {
    state: {
        filterUnsaturatedShifts: false,
        compactShifts: false,
        hideWorks: []
    },
    mutations: {
        filterUnsaturatedShifts(state, val) {
            state.filterUnsaturatedShifts = val
        },
        compactShifts(state, val) {
            state.compactShifts = val
        },
        hideWork(state, val) {
            if (!state.hideWorks.includes(val)) {
                state.hideWorks.push(val)
            }
        },
        showWork(state, val) {
            let index = state.hideWorks.indexOf(val)
            if (index !== -1) state.hideWorks.splice(index, 1)
        },
        toggleWork(state, val) {
            let index = state.hideWorks.indexOf(val)
            if (index === -1) {
                state.hideWorks.push(val)
            } else {
                state.hideWorks.splice(index, 1)
            }
        }
    },
    actions: {}
}
