import Vue from "vue"
import "./plugins/axios"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
import "./router/hooks"

import "./filters"
import "./utils"

import App from "./App.vue"

import FancyButton from "@/components/ui/FancyButton.vue"
import FancyForm from "@/components/ui/FancyForm.vue"
import FancyDialog from "@/components/ui/FancyDialog.vue"
import Stateful from "@/components/ui/Stateful.vue"
import StatefulCheckbox from "@/components/ui/StatefulCheckbox.vue"
import "@/assets/style.styl"

Vue.config.productionTip = false

Vue.component("fancy-button", FancyButton)
Vue.component("fancy-form", FancyForm)
Vue.component("fancy-dialog", FancyDialog)
Vue.component("stateful", Stateful)
Vue.component("stateful-checkbox", StatefulCheckbox)

var app = new Vue({
    vuetify,
    router,
    store,
    methods: {
        wait(ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        }
    },
    render: h => h(App)
})

app.$mount("#app")
