<template lang="pug">
div(:is="tag")
    slot(v-bind:state="state", @input="OnInput")
</template>

<script>
export default {
    name: "Stateful",
    props: {
        value: Boolean,
        tag: { default: "div", type: String }
    },
    data() {
        return {
            state: true
        }
    },
    watch: {
        value: {
            handler() {
                this.state = this.value
            },
            immediate: true
        }
    },
    methods: {
        OnInput(val) {
            this.state = val
            this.$emit("input", val)
        }
    }
}
</script>
