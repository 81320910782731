import Vue from "vue"
import Settings from "luxon/src/settings.js"
export default {
    state: {
        timezone: "local",
        ghostTimezone: "local",
        forceLocalTimezone: false,
        locale: "de-de"
    },
    mutations: {
        timezone(state, val) {
            state.ghostTimezone = val

            if (!state.forceLocalTimezone) {
                state.timezone = val
            } else {
                state.timezone = "local"
            }
            Settings.defaultZoneName = state.timezone
        },
        forceLocalTimezone(state, val) {
            state.forceLocalTimezone = val
        }
    },
    actions: {
        setLocalTimezone({ commit }) {
            commit("timezone", "local")
        },
        forceLocalTimezone({ state, commit }, val) {
            state.forceLocalTimezone = val
            commit("timezone", state.ghostTimezone)
        }
    }
}

Vue.prototype.$timezone = function() {
    return this.$store.state.settings.timezone
}

Vue.prototype.$locale = function() {
    return this.$store.state.settings.locale
}
