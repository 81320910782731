import axios from "axios"

export default {
    state: {
        maxAge: 300, // max cahche age in seconds
        cachedRequests: {}
    },
    mutations: {
        cacheRequest(state, entry) {
            state.cachedRequests[entry.url] = {
                timestamp: new Date(),
                permission: entry.permission
            }
        }
    },
    actions: {
        checkPermisionHead({ dispatch }, url) {
            return dispatch("checkPermision", { method: "HEAD", url: url })
        },
        checkPermision({ state, commit }, { url, method }) {
            if (!method) {
                method = "HEAD"
            } else {
                method = method.toUpperCase()
            }
            let cacheUrl = method + " " + url

            return new Promise((resolve, reject) => {
                if (
                    state.cachedRequests.hasOwnProperty(cacheUrl) &&
                    new Date() -
                        new Date(state.cachedRequests[cacheUrl].timestamp) <
                        state.maxAge * 1000
                ) {
                    if (state.cachedRequests[cacheUrl].permission) {
                        resolve()
                    } else {
                        reject()
                    }
                }
                axios({
                    method: method,
                    url: url
                })
                    .then(() => {
                        commit("cacheRequest", {
                            url: cacheUrl,
                            permission: true
                        })
                        resolve()
                    })
                    .catch(() => {
                        commit("cacheRequest", {
                            url: cacheUrl,
                            permission: false
                        })
                        reject()
                    })
            })
        }
    }
}
