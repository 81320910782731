import Vue from "vue"
import Vuetify, { VLayout } from "vuetify/lib"
import de from "vuetify/lib/locale/de"
import en from "vuetify/lib/locale/en"

const opts = {
    icons: {
        iconfont: "md" // default - only for display purposes
    },
    lang: {
        locales: { en, de },
        current: "de"
    },
    theme: {
        themes: {
            light: {
                primary: "#98BF0C",
                secondary: "#424242",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFB300"
            }
        }
    },
    components: {
        VLayout
    }
}
Vue.use(Vuetify)

Vue.prototype.$reqRule = [v => !!v || "Pflichtfeld"]

export default new Vuetify(opts)
