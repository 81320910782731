export default {
    state: {
        drawerOpen: false,
        drawerOpenedByUser: false,
        drawerClosedByUser: false
    },

    mutations: {
        drawerOpen(state, val) {
            state.drawerOpen = val
            state.drawerClosedByUser = false
            state.drawerOpenedByUser = false
        },
        drawerOpenUser(state, val) {
            state.drawerOpen = val
            state.drawerClosedByUser = !val
            state.drawerOpenedByUser = val
        },
        drawerMini(state, val) {
            state.drawerMini = val
        }
    },
    actions: {}
}
