export default {
    state: {
        lastValidHash: ""
    },

    mutations: {
        selectHashbang(state, val) {
            state.lastValidHash = val
        }
    },
    actions: {}
}
