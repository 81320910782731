<template lang="pug">
v-checkbox(v-model="state", :color="color")
</template>

<script>
export default {
    name: "StatefulCheckbox",
    props: {
        value: { type: Boolean, default: false },
        color: { type: null, default: null }
    },
    data() {
        return {
            state: true
        }
    },
    watch: {
        state: {
            handler(newVal) {
                if (this.newVal != this.value) {
                    this.$emit("input", newVal)
                }
            },
            immediate: true
        },
        value: {
            handler(newVal) {
                if (this.newVal != this.state) {
                    this.state = newVal
                }
            },
            immediate: true
        }
    }
}
</script>
