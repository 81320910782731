import Vue from "vue"
import Router from "vue-router"
import consts from "@/consts"
/* eslint-disable */
Vue.use(Router)

Vue.prototype.$getDefault = function(index) {
    if (this.$route.matched.length) {
        index =
            typeof index === "number" ? index : this.$route.matched.length - 1
        return this.$route.matched[index].instances.default
    }
    return null
}

Vue.prototype.$getNav = function(index) {
    if (this.$route.matched.length) {
        index =
            typeof index === "number" ? index : this.$route.matched.length - 1
        return this.$route.matched[index].instances.nav
    }
    return null
}

export default new Router({
    mode: "history",
    base: consts.baseUrl,
    routes: [
        {
            path: "/",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-index" */ "@/views/Index.vue"
                    )
            },
            meta: {
                drawer: true
            }
        },

        // EVENTS
        {
            path: "/events",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-events" */ "@/views/Events/Events.vue"
                    )
            }
        },
        {
            path: "/event/:event",
            name: "event",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/Event.vue"
                    )
            }
        },
        {
            path: "/event/:event/roles",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/EventRoles.vue"
                    )
            },
            meta: {
                title: "Rollen"
            }
        },
        {
            path: "/event/:event/comitees",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/Committees.vue"
                    )
            },
            meta: {
                title: "Komitees"
            }
        },
        {
            path: "/event/:event/stats",
            name: "event-stats",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event-stats" */ "@/views/Event/Stats.vue"
                    ),
                toolbar: () =>
                    import(
                        /* webpackChunkName: "group-event-stats" */ "@/views/Event/StatsToolbar.vue"
                    )
            },
            meta: {
                title: "Statistik"
            }
        },
            {
            path: "/event/:event/communication",
            name: "event-communication",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/Communication.vue"
                    )
            },
            meta: {
                title: "Kommunikation",
                drawer: false
            }
        },
        {
            path: "/event/:event/email",
            name: "event-email",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/EMail.vue"
                    )
            },
            meta: {
                title: "EMail",
                drawer: false
            }
        },
        {
            path: "/event/:event/crew-send-shift-overview",
            name: "event-crew-send-shift-overview",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/CrewSendShiftOverview.vue"
                    )
            },
            meta: {
                title: "Schichtpläne",
                drawer: false
            }
        },
        {
            path: "/event/:event/covid19",
            name: "event-covid19",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event" */ "@/views/Event/Communication/Covid19.vue"
                    )
            },
            meta: {
                title: "Covid-19",
                drawer: false
            }
        },
        {
            path: "/event/:event/bookings",
            name: "event-bookings",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-bookings" */ "@/views/Event/Bookings.vue"
                    )
            },
            meta: {
                title: "Buchungen",
                drawer: false
            }
        },
        {
            path: "/event/:event/crew/list",
            name: "event-crew-list",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event-crew" */ "@/views/Event/CrewList.vue"
                    )
            },
            meta: {
                title: "Crew",
                drawer: false
            }
        },
        {
            path: "/event/:event/crew/:crew/profile",
            name: "event-crew-profile",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event-crew" */ "@/views/Event/CrewProfile.vue"
                    )
            },
            meta: {
                title: "Crew Profil",
                drawer: false
            }
        },
        {
            path: "/event/:event/total",
            name: "event-total",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event-shifts" */ "@/views/Event/EventTotalView.vue"
                    ),
                toolbar: () =>
                    import(
                        /* webpackChunkName: "group-event-shifts" */ "@/views/Event/EventTotalToolbar.vue"
                    )
            },
            meta: {
                title: "Gesamt",
                drawer: false
            }
        },
        {
            path: "/event/:event/work/:work",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-event-shifts" */ "@/views/Work/Main.vue"
                    ),
                toolbar: () =>
                    import(
                        /* webpackChunkName: "group-event-shifts" */ "@/views/Work/Toolbar.vue"
                    )
            },
            meta: {
                title: "Gewerk",
                drawer: true
            }
        },
        {
            path: "/crew",
            name: "crew",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-account" */ "@/views/Crew/CrewBase.vue"
                    )
            },
            children: [
                {
                    path: "activate",
                    components: {
                        default: () =>
                            import(
                                /* webpackChunkName: "group-account" */ "@/views/Crew/CrewActivate.vue"
                            )
                    }
                },
                {
                    path: "create",
                    name: "create",
                    component: () =>
                        import(
                            /* webpackChunkName: "group-account" */ "@/views/Account/CrewCreate.vue"
                        )
                }
            ]
        },
        {
            path: "/invoice/self-service/:invoice",
            name: "invoice-self-service",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-selfservice" */ "@/views/Invoice/SelfService.vue"
                    )
            },
            meta: {
                title: "Rechnung",
                drawer: false,
                noAuth: true
            }
        },
        {
            path: "/self-service/chip/:chip",
            name: "self-service-chip",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-selfservice" */ "@/views/SelfService/Chip.vue"
                    )
            },
            meta: {
                title: "Übersicht",
                drawer: false,
                noAuth: true
            }
        },
        {
            path: "/login",
            name: "login",
            component: () =>
                import(
                    /* webpackChunkName: "group-account" */ "@/views/Account/Login.vue"
                ),
            meta: {
                title: "",
                noDrawer: true,
                noAuth: true
            }
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () =>
                import(
                    /* webpackChunkName: "group-account" */ "@/views/Account/ForgotPassword.vue"
                ),
            meta: {
                title: "",
                noDrawer: true,
                noAuth: true
            }
        },
        {
            path: "/forgot-password/reset",
            name: "forgot-password-reset",
            component: () =>
                import(
                    /* webpackChunkName: "group-account" */ "@/views/Account/ForgotPasswordReset.vue"
                ),
            meta: {
                title: "",
                noDrawer: true,
                noAuth: true
            }
        },
        {
            path: "/register",
            name: "register",
            component: () =>
                import(
                    /* webpackChunkName: "group-account" */ "@/views/Account/Register.vue"
                ),
            meta: {
                title: "",
                noDrawer: true,
                noAuth: true
            }
        },
        {
            path: "/account",
            name: "account",
            component: () =>
                import(
                    /* webpackChunkName: "group-account" */ "@/views/Account/View.vue"
                ),
            meta: {
                title: "Account"
            }
        },
        {
            path: "/settings",
            name: "settings",
            component: () =>
                import(
                    /* webpackChunkName: "group-account" */ "@/views/Settings.vue"
                ),
            meta: {
                title: "Einstellungen"
            }
        },
        {
            path: "/myshifts",
            name: "myshifts",
            components: {
                default: () =>
                    import(
                        /* webpackChunkName: "group-myshifts" */ "@/views/MyShifts/MyShiftsOverview.vue"
                    ),
                toolbar: () =>
                    import(
                        /* webpackChunkName: "group-myshifts" */ "@/views/MyShifts/MyShiftsOverviewToolbar.vue"
                    )
            },
            meta: {
                drawer: false
            }
        }
    ]
})
